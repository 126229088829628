<template lang="">
  <div class="application">
    <Banner :title="application"></Banner>
    <Title :title="application"></Title>
    <div class=" wei-container flex-row-top">
      <div class="application_bar " v-if="applicationList">
        <div
          class="application_item pointer"
          v-for="(item, index) in applicationList"
          :key="index + ''"
          :index="index + ''"
          :class="{ active: activeApplication.name == item.name }"
           @click="changeCatefoy(item)"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="application_container ">
        <div class="name">
          {{ activeApplication.name }}
        </div>
        <div class="content" v-html="activeApplication.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "@/components/title";
import Banner from "@/components/Banner";
import { mapGetters, mapMutations } from "vuex";
import { queryProductList, queryApplicationList } from "@/api/product";
export default {
  components: {
    Title,
    Banner,
  },
  data() {
    return {
      application: {},
      applicationList: [],
      activeApplication: {},
      total: 1,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.application = this.$t("application");
    this.getQueryData();
  },
  mounted() {},
  methods: {
    getQueryData() {
      queryApplicationList().then((res) => {
        this.applicationList = res.data;
        if (res.data && res.data.length) {
          this.activeApplication = res.data[0];
        }
      });
    },

    changeCatefoy(item) {
      this.activeApplication = item;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    download(file) {
      download(file);
    },
  },
};
</script>
<style lang="scss" scoped>
.application {
  padding-bottom: 200px;
  .wei-container {
    padding: 0;
  }
  .application_bar {
    margin-right: 100px;
    .application_item {
      width: 240px;
      height: 80px;
      line-height: 80px;
      background: #eeeeee;
      font-size: 18px;
      padding: 0 20px;
      margin-bottom: 2px;
      color: #aaaaaa;
      &.active {
        color: #ffffff;
        background: #19222f;
      }
    }
  }
  .application_container {
    .name {
      font-size: 18px;
      font-weight: bold;
      color: #19222f;
      text-align: left;
      margin-bottom: 40px;
    }
    .content {
      font-size: 14px;
      color: #19222f;
    }
  }
}
</style>
<style lang="scss" >
.application {
  .c-title {
    .cname {
      margin-top: 100px;
    }
  }
}
</style>
